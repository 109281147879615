
import {Vue, Component, Inject, Emit, Watch} from 'vue-property-decorator'
import AppLayoutDefault from '@/components/layout/AppLayoutDefault'
import DataTable from '@/components/entity/DataTable'
import type {TDataTableConstruction, TSortRecord} from '@/components/entity/DataTable/DataTable.vue'
import SwitchInput from '@/components/ui/SwitchInput'
import Checkbox from '@/components/ui/Checkbox'
import Input from '@/components/ui/Input'
import Button from '@/components/ui/Button'
import Loader from '@/components/ui/Loader'
import Pagination from '@/components/entity/Pagination'
import DataTableMenu from '@/components/entity/DataTableMenu'
import {TAPIEmployee, TAPIEmployeeStatus} from '@/types/api.type'
import Modal from '@/components/entity/Modal'
import ModalLimit from '@/components/entity/ModalLimit'
import {TModalLimitFormData, TModalSubmitData} from '@/components/entity/ModalLimit/ModalLimit.vue'
import {ApiService} from '@/services/api.service'
import ErrorNotifierService from '@/services/errorNotifier.service'
import {RobotWS} from '@/services/robotws.service'
import {RobotWSMessage} from '@/types/robotws.type'
import {throttle} from 'lodash'
import {phoneabled} from '@/utils/phoneString.util'

const PAGE_SIZE = 20
const ERR_EMPLOYEE_ID = {
  title: 'Ошибка клиента',
  message: 'Не верный ID сотрудника, для получения помощи свяжитесь с менеджером'
}
const ERR_EMPLOYEE_NOT_FOUND = {
  title: 'Ошибка клиента',
  message: `Сотрудник не найден, для получения помощи свяжитесь с менеджером`
}

@Component({
  components: {
    AppLayoutDefault,
    DataTable,
    SwitchInput,
    Checkbox,
    'TextInput': Input.Text,
    Button,
    Loader,
    Pagination,
    DataTableMenu,
    Modal,
    ModalLimit
  }
})
export default class AppFoodLimit extends Vue {
  @Inject()
  readonly $api!: ApiService

  @Inject()
  readonly $error!: ErrorNotifierService

  @Inject()
  readonly $ws!: RobotWS

  LIMIT_MAX = 999999
  EMPLOYEES_LINK = window.location.origin + '/employees'

  loading: boolean = true
  searchInputLoading: boolean = false

  page = 1
  pageSize = PAGE_SIZE
  pageCount = 1

  sortRules: TSortRecord = {
    fullName: {
      ascending: (a: TAPIEmployee, b: TAPIEmployee) => a.fullName.localeCompare(b.fullName),
      descending: (a: TAPIEmployee, b: TAPIEmployee) => b.fullName.localeCompare(a.fullName)
    },
    phone: {
      ascending: (a: TAPIEmployee, b: TAPIEmployee) => a.phone.localeCompare(b.phone),
      descending: (a: TAPIEmployee, b: TAPIEmployee) => b.phone.localeCompare(a.phone)
    },
    status: {
      ascending: (a: TAPIEmployee, b: TAPIEmployee) => Number(a.status === 'enabled'),
      descending: (a: TAPIEmployee, b: TAPIEmployee) => Number(b.status === 'enabled')
    },
    limit: {
      ascending: (a: TAPIEmployee, b: TAPIEmployee) => Number(a.limit > b.limit),
      descending: (a: TAPIEmployee, b: TAPIEmployee) => Number(a.limit < b.limit)
    }
  }

  search = ''
  searchData: TAPIEmployee[] | null = null
  searchPage: number = 1
  searchPagesCount: number = 0

  tableData: TAPIEmployee[] = []
  tableConstruction: TDataTableConstruction[] = [
    {name: 'fullName', title: 'Сотрудник', sortable: true, basis: '20%'},
    {name: 'phone', title: 'Телефон', sortable: true, basis: '21%'},
    {name: 'status', title: 'Статус услуги', sortable: true, basis: '21%'},
    {name: 'limit', title: 'Лимит, ₽/мес', sortable: true, basis: '31%'}
  ]

  statusText = {
    enabled: 'Включена',
    disabled: 'Отключена',
    processEnabled: 'В процессе подключения',
    processDisabled: 'В процессе отключения'
  }

  limitModalShown: boolean = false
  selectedEmployeeIds: string[] = []

  employeeSingleId: string | null = null
  employeeSingleTitle: string = ''
  employeeSingleData: TModalLimitFormData | null = null
  employeeSingleWarning: boolean = false

  subscribedEmployeeIds: string[] = []

  throttledSerchInput = throttle(this.onSearchInput, 700, {leading: false})

  get isSearchActive () {
    return !!(this.search.length >= 2)
  }

  get isSearchMode () {
    return !!this.searchData && Array.isArray(this.searchData)
  }

  get dataKey () {
    return this.isSearchMode ? 'searchData' : 'tableData'
  }

  get activeData () {
    return this.searchData ?? this.tableData
  }

  get isPaginationVisible () {
    const pagesCount = this.isSearchActive ? this.searchPagesCount : this.pageCount

    return pagesCount > 1
  }

  @Watch('limitModalShown')
  onModalStateChanged (newValue: boolean) {
    if (!newValue) {
      this.employeeSingleId = null
      this.employeeSingleTitle = ''
      this.employeeSingleData = null
      this.employeeSingleWarning = false
    }
  }

  @Watch('search')
  onSearchChanged (newValue: string) {
    if (newValue === '') {
      this.searchData = null
    }
    this.throttledSerchInput()
  }

  @Emit('redirect')
  emitRedirect () {}

  async created () {
    try {
      const response = await this.$api.fetchEmployeesList(this.page, this.pageSize)
      const {data} = response
      const {page, pageSize, pagesCount} = data.meta.pagination

      this.page = page
      this.pageSize = pageSize
      this.pageCount = pagesCount
      this.tableData = data.data

      // ws settings
      this.subscribedEmployeeIds = data.data.map(item => item.externalEmployeeID)
      await this.subscribe()
    } catch (_: unknown) {
      this.$error.push({
        title: 'Ошибка сервера',
        message: 'Не удалось получить список сотрудников, для получения помощи свяжитесь с менеджером'
      })
    } finally {
      this.loading = false
    }
  }

  async subscribe () {
    try {
      const verifiedEmployeeIds = Array.from(new Set(this.subscribedEmployeeIds))

      await this.$ws.connect(verifiedEmployeeIds)
      this.$ws.socket.onmessage = this.onWSMessage.bind(this)
      this.$ws.socket.onerror = this.onWSError
    } catch (error) {
      console.warn(error)
    }
  }

  async addSubscribers (employees: TAPIEmployee[]) {
    let isSubscribedIdsChanged = false
    const externalEmployeeIds = employees.map(item => item.externalEmployeeID)

    employees.forEach(item => {
      if (!(this.subscribedEmployeeIds.includes(item.externalEmployeeID))) {
        externalEmployeeIds.push(item.externalEmployeeID)
        isSubscribedIdsChanged = true
      }
    })

    try {
      if (isSubscribedIdsChanged) this.$ws.close()

      await this.subscribe()
    } catch (error) {
      this.$error.push({
        title: 'Ошибка обновления',
        message: 'Не удалось обновить список сотрудников, для получения помощи свяжитесь с менеджером'
      })
    }
  }

  async onPaginationInput (_page: number, searchMode: boolean = false) {
    try {
      const response = await this.$api.fetchEmployeesList(_page, this.pageSize, searchMode ? this.search : undefined)
      const {data} = response
      const {page} = data.meta.pagination

      if (searchMode) {
        this.searchPage = page
        this.searchData = data.data

        this.$ws.close()
        this.addSubscribers(data.data)
      } else {
        this.page = page
        this.tableData = data.data

        this.$ws.close()
        const externalEmployeeIds = data.data.map(item => item.externalEmployeeID)
        await this.$ws.connect(externalEmployeeIds)
        this.$ws.socket.onmessage = this.onWSMessage.bind(this)
        this.$ws.socket.onerror = this.onWSError
      }
    } catch (error) {
      this.$error.push({
        title: 'Ошибка сервера',
        message: 'Не удалось получить список сотрудников, для получения помощи свяжитесь с менеджером'
      })
    }
  }

  async onSearchInput () {
    if (!this.search || this.search.length < 2) return

    this.searchInputLoading = true

    try {
      const response = await this.$api.fetchEmployeesList(1, PAGE_SIZE, this.search)
      const {data} = response
      const {pagesCount} = response.data.meta.pagination

      this.searchData = data.data

      // pagination settings
      this.searchPage = 1
      this.searchPagesCount = pagesCount

      // ws settings
      await this.addSubscribers(data.data)
    } catch (error) {
      this.$error.push({
        title: 'Ошибка сервера',
        message: 'Не удалось получить список сотрудников, для получения помощи свяжитесь с менеджером'
      })
    } finally {
      this.searchInputLoading = false
    }
  }

  async onChangeStatusAll (selectedIds: string[], status: TAPIEmployeeStatus) {
    const reservedRowData = [...this.activeData]

    try {
      const updatedTableData = reservedRowData.map(item => {
        if (selectedIds.includes(item.employeeID)) {
          item.status = status
          item.unlimited = true
          item.limit = status === 'processDisabled' ? 0 : Number(item.limit)
        }

        return item
      })
      const updatedTableDataDto = updatedTableData.filter(item => selectedIds.includes(item.employeeID))

      this[this.dataKey] = updatedTableData

      if (updatedTableDataDto.length) {
        await this.$api.patchEmployeeSettings(updatedTableDataDto)
      } else {
        this.$error.push({
          title: 'Ошибка клиента',
          message: 'Не удалось обработать список сотрудников, для получения помощи свяжитесь в менеджером'
        })
      }
    } catch (error) {
      this[this.dataKey] = reservedRowData

      this.$error.push({
        title: 'Ошибка сервера',
        message: 'Не удалось изменить статус сотрудников, для получения помощи свяжитесь с менеджером'
      })
    }
  }

  async onStatusChanged (value: boolean, employeeID: string) {
    const employeeIndex = this.activeData.findIndex(item => item.employeeID === employeeID)
    const employee = {...this.activeData[employeeIndex]}

    if (employee) {
      const reservedEmployee = {...employee}

      employee.status = value ? 'processEnabled' : 'processDisabled'
      employee.unlimited = true
      employee.limit = employee.status === 'processDisabled' ? 0 : Number(employee.limit)

      this.updateEmployee(employee)

      try {
        await this.$api.patchEmployeeSettings([employee])
      } catch (error) {
        this.$error.push({
          title: 'Ошибка сервера',
          message: 'Не удалось изменить статус сотрудников, для получения помощи свяжитесь с менеджером'
        })

        this.updateEmployee(reservedEmployee)
      }
    }
  }

  async onLimitChanged (value: string, employeeID: string) {
    const numLimit = Number(value)

    if (isNaN(numLimit)) {
      return this.$error.push(ERR_EMPLOYEE_ID)
    }

    const employeeIndex = this.getRowIndexByEmployeeId(employeeID)

    if (employeeIndex === null) {
      return this.$error.push(ERR_EMPLOYEE_NOT_FOUND)
    }

    const employee = this.activeData[employeeIndex]
    const reservedEmployee = {...employee}

    employee.limit = numLimit
    employee.unlimited = numLimit === 0
    employee.status = 'processEnabled'

    this.updateEmployee(employee)

    try {
      await this.$api.patchEmployeeSettings([employee])
    } catch (error) {
      this.updateEmployee(reservedEmployee)

      this.$error.push({
        title: 'Ошибка сервера',
        message: `Произошла ошибка при сохранении данных о лимитах, для получения помощи свяжитесь с менеджером`
      })
    }
  }

  async onUnlimitedChanged (value: boolean, employeeID: string) {
    const employeeIndex = this.getRowIndexByEmployeeId(employeeID)

    if (employeeIndex === null) {
      return this.$error.push(ERR_EMPLOYEE_ID)
    }

    const employee = this.activeData[employeeIndex]

    if (!employee) {
      return this.$error.push(ERR_EMPLOYEE_NOT_FOUND)
    }

    const reservedEmployee = {...employee}

    if (!value) {
      this.employeeSingleWarning = true
      this.onActionClick(employeeID)
    } else {
      employee.limit = 0
      employee.unlimited = value
      employee.status = 'processEnabled'

      this.updateEmployee(employee)

      try {
        await this.$api.patchEmployeeSettings([employee])
      } catch (error) {
        this.updateEmployee(reservedEmployee)

        this.$error.push({
          title: 'Ошибка сервера',
          message: `Произошла ошибка при сохранении данных о лимитах, для получения помощи свяжитесь с менеджером`
        })
      }
    }
  }

  updateEmployee (employee: TAPIEmployee) {
    const tableDataIndex = this.tableData.findIndex(_ => _.employeeID === employee.employeeID)

    if (tableDataIndex >= 0) {
      this.$set(this.tableData, tableDataIndex, employee)
    }

    if (this.isSearchMode) {
      const searchDataIndex = this.searchData!.findIndex(_ => _.employeeID === employee.employeeID)

      if (searchDataIndex >= 0) {
        this.$set(this.searchData!, searchDataIndex, employee)
      }
    }
  }

  getRowIndexByEmployeeId (employeeID: string): number | null {
    const index = this.activeData.findIndex(item => item.employeeID === employeeID)

    return index < 0 ? null : index
  }

  onLimitControl (selectedIds: string[]) {
    this.selectedEmployeeIds = selectedIds
    this.limitModalShown = true
  }

  onLimitFormSubmit (data: TModalSubmitData) {
    const {status, limit, unlimited} = data.data
    const {employeeIds} = data

    this.tableData = this.tableData.map(item => {
      if (employeeIds.includes(item.employeeID)) {
        item.status = status
        item.limit = Number(limit) ?? 0
        item.unlimited = unlimited
      }

      return item
    })

    if (this.searchData?.length) {
      this.searchData = this.searchData.map(item => {
        if (employeeIds.includes(item.employeeID)) {
          item.status = status
          item.limit = Number(limit) ?? 0
          item.unlimited = unlimited
        }

        return item
      })
    }
  }

  getProcessedLimit (limit: string | number) {
    const numLimit = Number(limit)

    return numLimit === 0 ? '' : limit
  }

  onActionClick (id: string, initialDataOverride: Partial<TModalLimitFormData> | null = null) {
    const item = this.activeData.find(_ => _.employeeID === id)

    if (item?.status === 'processEnabled' || item?.status === 'processDisabled') {
      return
    }

    if (item) {
      this.employeeSingleId = id
      this.employeeSingleTitle = `${item.fullName}<small>${phoneabled(item.phone)}</small>`
      this.employeeSingleData = item
      this.employeeSingleData.limit = Number(this.employeeSingleData.limit) === 0 ? '' : this.employeeSingleData.limit

      if (initialDataOverride && Object.keys(initialDataOverride).length) {
        (Object.keys(initialDataOverride) as (keyof Partial<TModalLimitFormData>)[]).forEach((key) => {
          // @ts-ignore
          this.$set(this.employeeSingleData, key, initialDataOverride[key])
        })
      }

      this.limitModalShown = true
    } else {
      this.$error.push({
        title: 'Ошибка клиента',
        message: 'Не найден ID сторудника, для получения помощи свяжитесь с менеджером'
      })
    }
  }

  onWSMessage (event: MessageEvent<string>) {
    const data = JSON.parse(event.data) as RobotWSMessage

    if (data.errorMessage) {
      return this.$error.push({
        title: 'Ошибка обновления статуса',
        message: 'Не удалось изменить статус сотрудников, для получения помощи свяжитесь с менеджером'
      })
    }

    const rowIndex = this.tableData.findIndex(item => item.externalEmployeeID === data.externalEmployeeID)
    const row = this.tableData[rowIndex]

    if (row) {
      row.status = data.status
      this.$set(this.tableData, rowIndex, row)
    }
  }

  onWSError () {
    this.$error.push({
      title: 'Ошибка подключения к сервису обновления данных',
      message: 'Возможна не корректность в информации о статусе сотрудников, , для получения помощи свяжитесь с менеджером'
    })
  }
}
